import { ResourceHeaders } from '..'
import { AppraisalStatusHeader, ContactHeader, IdHeader, VehicleHeader, VehicleStatusHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Stock', value: 'auto' },
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'stock' },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'sale.deal.lead.executive.person.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['sale.deal.lead.executive.phone'],
    },
  },
  { ...AppraisalStatusHeader, text: 'Estado traspaso', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
