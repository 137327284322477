import { ResourceHeaders } from '..'
import {
  ContactHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  FinancialHeader,
  IdHeader,
  PersonHeader,
  PriceHeader,
  RedirectDtHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'
import { Process } from '@/entities/settings'
import { Financing } from '@/entities/loans'

export const headers: ResourceHeaders = [
  {
    ...TextHeader,
    text: 'Nº',
    value: '@',
    width: 130,
  },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'client.name',
    width: 200,
  },
  {
    ...TextHeader,
    text: 'Solicitante',
    value: 'applicant.name',
    width: 200,
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'saleOrder.deal',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'Stock' }, id: item.stock.id }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'saleOrder.deal.stock', width: 200 },
  { ...DateHeader, text: 'Fecha solicitud  ', value: 'created' },
  { ...TextHeader, text: 'Pie ', value: 'pie' },
  { ...PriceHeader, text: 'Monto solicitado', value: 'startingAmount', width: 160 },
  { ...FinancialHeader, text: 'Financiamiento', value: 'evaluation' },
  { ...TextHeader, text: 'Mejor comisión', value: 'bestCommission', width: 200 },
  {
    ...RedirectDtHeader,
    text: 'Evaluaciones',
    value: '@',
    options: {
      path: '/datatable/supervisor/supervisor_evaluations',
      query: {
        financing: {
          id: {
            _eq: '$id',
          },
        },
      },
    },
  },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'saleOrder.deal.lead.executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Financing, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['saleOrder.deal.lead.executive.secondaryPhone.work'],
    },
  },
  {
    ...ContactHeader,
    text: 'F & I',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'financing_executive',
      },
      path: 'responsible.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Financing, process: Process, system: string) => Helpers.contact(item, 'financing_executive', process, system),
      },
      disabledMissingProperty: ['responsible.phone'],
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
