import { ResourceHeaders } from '..'
import { DailyAccountAlertHeader, IdHeader, PriceHeader, TextHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...DailyAccountAlertHeader, text: 'Alerta', value: '@' },
  { ...TextHeader, text: 'Día', value: 'dateFixed', width: 180 },
  { ...TextHeader, text: 'Banco', value: 'bankAccount.alias', width: 200 },
  { ...TextHeader, text: 'Cuenta', value: 'bankAccount.accountNumber', width: 220 },
  {
    ...PriceHeader,
    text: 'Saldo contable',
    value: 'balance',
    width: 180,
  },
  { ...PriceHeader, text: 'Saldo disponible', value: 'available', width: 180 },
  {
    ...PriceHeader,
    text: 'Variación diaria',
    value: 'variant',
    width: 180,
    options: { icon: 'mdi-currency-usd', tooltip: false, showIcon: true },
  },
  { ...PriceHeader, text: 'Linea de crédito utilizado', value: 'creditLine', width: 220 },
  {
    ...PriceHeader,
    text: 'Linea de crédito disponible',
    value: 'creditAvailable',
    width: 220,
  },
  {
    ...PriceHeader,
    text: 'Variación de crédito',
    value: 'creditVariantValue',
    width: 220,
    options: { icon: 'mdi-arrow-up', oppositeIcon: 'mdi-arrow-down', tooltip: false, showIcon: true },
  },
]

export default function (): ResourceHeaders {
  return headers
}
