import { RoleResources } from '..'
import inspection from './inspection'
import enablement from './enablement'
import stock from './stock'
import stockDocumentation from './stockDocumentation'

export const inspector: RoleResources = {
  inspector_inspections: inspection,
  inspector_enablement: enablement,
  inspector_stock: stock,
  inspector_stock_documentation: stockDocumentation,
}
