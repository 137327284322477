import { RoleResources } from '..'

import appraisal from './appraisal'
import inspection from './inspection'
import negotiation from './negotiation'
import leads from './leads'
import stock from './stock'
import enablement from './enablement'
import purchase from './purchase'
import sales from './sales'
import reservations from './reservations'
import consignment from './consignment'
import financing from './financing'
import evaluation from './evaluation'

export const supervisor: RoleResources = {
  supervisor_appraisals: appraisal,
  supervisor_inspections: inspection,
  supervisor_negotiations: negotiation,
  supervisor_leads: leads,
  supervisor_stock: stock,
  supervisor_enablement: enablement,
  supervisor_purchases: purchase,
  supervisor_sales: sales,
  supervisor_financings: financing,
  supervisor_reservations: reservations,
  supervisor_consignments: consignment,
  supervisor_evaluations: evaluation,
}
