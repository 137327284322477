import { ResourceHeaders } from '..'

import {
  AlertsHeader,
  AppraiserHeader,
  BusinessHeader,
  ChannelHeader,
  ContactHeader,
  DateHeader,
  FinancialHeader,
  IdHeader,
  LeadActivityHistoryHeader,
  StatusAppraisalCellHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...LeadActivityHistoryHeader, text: 'Vencimiento tarea', value: '@', sortable: true },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...ChannelHeader, text: 'Canal', value: 'channel' },
  { ...TextHeader, value: 'client.shortName', text: 'Cliente', width: 200 },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'executive.person.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['executive.phone'],
    },
  },
  { ...VehicleHeader, text: 'Stock', value: 'sale.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'sale.stock' },
  { ...AppraiserHeader, text: 'Tasación', value: 'purchase' },
  { ...StatusAppraisalCellHeader, text: 'Estado tasación', value: 'purchase.appraisal' },
  { ...FinancialHeader, text: 'Financiamiento', value: 'sale.order.financing.evaluation' },
]

export default function (): ResourceHeaders {
  return headers
}
