import { ResourceHeaders } from '..'
import {
  AddressHeader,
  BusinessHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  InspectionProgressHeader,
  InspectionQualificationHeader,
  InspectionStatusHeader,
  InspectionSummary,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Process } from '@/entities/settings'
import { Inspection } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'appraisal.deal.auto' },
  { ...DateHeader, text: 'Fecha Agendada', value: 'scheduled' },
  { ...DateHeader, text: 'Fecha Inicio', value: 'dateFormatted' },
  { ...AddressHeader, text: 'Ubicación', value: 'address' },
  { ...BusinessHeader, text: 'Negocio ', value: 'appraisal.deal.lead.pipeline' },
  { ...InspectionStatusHeader, text: 'Estado', value: '@' },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'executive.person.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Inspection, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['inspector.phone'],
    },
  },
  {
    ...ContactHeader,
    text: 'Inspector',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'inspector',
      },
      path: 'inspector.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Inspection, process: Process, system: string) => Helpers.contact(item, 'inspector', process, system),
      },
      disabledMissingProperty: ['inspector.phone'],
    },
  },
  {
    ...ContactHeader,
    text: 'Supervisor',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'supervisor',
      },
      path: 'supervisor.name',
      action: {
        always: true,
        color: 'yellow',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Inspection, process: Process, system: string) => Helpers.contact(item, 'supervisor', process, system),
      },
      disabledMissingProperty: ['supervisor.phone'],
    },
  },
  {
    ...InspectionSummary,
    text: 'Reporte',
    value: '@',
    options: {
      tooltip: 'Reporte',
      disabled: true,
      action: {
        close: true,
        color: 'purple',
        icon: 'mdi-clipboard-text-search-outline',
        only: true,
      },
    },
  },
  { ...InspectionProgressHeader, text: 'Progreso inspector', value: '@' },
  { ...InspectionQualificationHeader, text: 'Calificación inspector', value: 'qualificationInspector' },
  { ...InspectionProgressHeader, text: 'Progreso supervisor', value: '@', options: { supervisor: true } },
  { ...InspectionQualificationHeader, text: 'Calificación supervisor', value: 'qualification' },
]

export default function (): ResourceHeaders {
  return headers
}
