import { ResourceHeaders } from '..'
import { AppraisalStatusHeader, BackupPaymentHeader, IdHeader, TextHeader, VehicleHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      titleTooltip: 'Anular',
      action: {
        remove: Helpers.removeLinkedModel,
      },
    },
    width: 130,
  },
  { ...TextHeader, text: 'Fecha', value: 'created', width: 120 },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  { ...TextHeader, text: 'Forma de pago', value: 'type.description', width: 120 },
  { ...TextHeader, text: 'Monto', value: 'amountFixed', width: 60 },
  { ...TextHeader, text: 'Pagador', value: 'payer.applicantWithRut', width: 220 },
  { ...TextHeader, text: 'Cuenta de abono', value: 'financialAccount.bankAccountShortName', width: 120 }, // payment count
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
  { ...TextHeader, text: 'Validador', value: 'responsible.person.shortName', width: 120 },
  {
    ...BackupPaymentHeader,
    text: 'Detalle',
    value: '@',
    width: 60,
    sortable: false,
    options: {
      name: 'payment_receipt',
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
