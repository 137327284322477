import { ResourceHeaders } from '..'
import {
  AlertPurchaseHeader,
  AppraisalStatusHeader,
  ChannelHeader,
  DateHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { CellType } from '@/components/dataTables/cell'
import { PurchaseOrder } from '@/entities/purchase'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...AlertPurchaseHeader, text: 'Negocio', value: '@', width: 160 },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'negotiation.inspection.appraisal.deal.lead.client.applicantWithRut',
    width: 140,
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...DateHeader, text: 'Fecha de compra ', value: 'created' },
  { ...AppraisalStatusHeader, text: 'Estado de compra', value: '@', width: 140 },
  {
    align: 'center',
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'negotiation.executive.person.name',
      action: {
        color: 'green',
        always: true,
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: PurchaseOrder, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['negotiation.executive.phone'],
    },
    width: 250,
  },
  { ...TextHeader, text: 'Precio autorizado', value: 'authorizePrice', width: 120 },
  { ...TextHeader, text: 'Precio de compra', value: 'amount', width: 120 },
  { ...TextHeader, text: 'Saldo a favor', value: 'marginPositive', width: 120 },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 120 },
]

export default function (): ResourceHeaders {
  return headers
}
