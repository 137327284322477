import { ResourceHeaders } from '..'
import {
  AlertsHeader,
  IdHeader,
  PhotoHeader,
  StockCharacteristicsHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  extendsObj<TableCellHeader>({
    text: 'Nº',
    value: '@',
    width: 130,
    options: {
      removeOff: true,
      expandOff: true,
      show: false,
    },
  }, IdHeader),
  { ...PhotoHeader, text: 'Foto', value: '@' },
  { ...AlertsHeader, text: 'Alerta', value: 'alerts', sortable: true },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  { ...TextHeader, text: 'Llave', value: 'keyIdentifier.keyId', width: 120 },
  { ...StockCharacteristicsHeader, text: 'Característica', value: 'auto' },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
]

export default function (): ResourceHeaders {
  return headers
}
