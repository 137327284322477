import { ResourceHeaders } from '..'
import {
  ActionHeader,
  AlertStockHeader,
  IdHeader,
  MarketPricesHeader,
  PhotoHeader,
  PublishPlatformHeader,
  RemoveStockPublicationHeader,
  RepublishStockHeader,
  StockDailyProgressHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { CellType, TableCellHeader } from '@/components/dataTables/cell'
import { extendsObj } from '@/utils/vuetify/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      removeOff: true,
      show: false,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertStockHeader, text: 'Alerta', value: '@', sortable: true },
  { ...PhotoHeader, text: 'Foto', value: '@' },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado stock', value: 'stockStatus' },
  extendsObj<TableCellHeader>({
    text: 'Precio y descuento',
    value: 'priceDiscount',
    width: 240,
    sortable: true,
    options: {
      path: 'priceAmount',
    },
  }, MarketPricesHeader),
  { ...StockDailyProgressHeader, text: 'Días en stock', value: '@' },
  {
    ...MarketPricesHeader,
    text: 'Leads',
    value: 'received',
    width: 180,
    options: {
      component: CellType.text,
      options: {
        align: 'start',
      },
    },
  },
  { ...TextHeader, text: 'Llave', value: 'keyIdentifier.keyId', width: 120 },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
  {
    ...PublishPlatformHeader,
    text: 'Chileautos',
    value: '@',
    options: {
      platformName: 'chileautos',
    },
  },
  {
    ...PublishPlatformHeader,
    text: 'Mercadolibre',
    value: '@',
    options: {
      platformName: 'mercadolibre',
    },
  },
  {
    ...PublishPlatformHeader,
    text: 'Yapo',
    value: '@',
    options: {
      platformName: 'yapo',
    },
  },
  {
    ...RepublishStockHeader,
    text: 'Republicar',
    value: '@',
  },
  {
    ...RemoveStockPublicationHeader,
    text: 'Eliminar publicación',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
