import { ResourceHeaders } from '..'

import {
  AlertAppraisalHeader,
  BusinessHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  IndicatorHeader,
  PriceHeader,
  StatusAppraisalCellHeader,
  StockCharacteristicsHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertAppraisalHeader, text: 'Alerta', value: '@', sortable: false, width: 100 },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  { ...DateHeader, text: 'Creación', value: 'created', sortable: false },
  { ...TextHeader, text: 'Kilometraje', value: 'mileageFormatted' },
  { ...StockCharacteristicsHeader, text: 'Característica', value: 'deal.auto' },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['executive.phone'],
    },
  },
  { ...BusinessHeader, text: 'Negocio ', value: 'deal.lead.pipeline' },
  { ...StatusAppraisalCellHeader, text: 'Estado tasación', value: '@' },
  { ...IndicatorHeader, text: 'Indicadores', value: 'indicator' },
  /* { ...MarketPricesHeader, text: 'Precios de mercado', value: 'market.overview' }, */
  { ...PriceHeader, text: 'Diferencia para compra', value: 'agreement', width: 160 },
  { ...PriceHeader, text: 'Diferencia para consignacion', value: 'agreementConsignment', width: 160 },
]

export default function (): ResourceHeaders {
  return headers
}
