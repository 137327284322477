import { ResourceHeaders } from '..'
import {
  ChannelHeader,
  ConsignmentExpirationHeaders,
  ConsignmentHeaders,
  DateHeader,
  IdHeader,
  PersonHeader,
  PriceHeader,
  StockButtonHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { PurchaseOrder } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'client',
      },
      path: 'negotiation.client.name',
      displayPhone: true,
      trigger: ({
        negotiation: {
          inspection: { appraisal: { deal: { lead: { client } } } },
        },
      }: PurchaseOrder) => Helpers.linkedModel(client),
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['negotiation.client.phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...ConsignmentExpirationHeaders, text: 'Vencimiento', value: '@' },
  { ...ConsignmentHeaders, text: 'Estado', value: '@' },
  { ...PriceHeader, text: 'Precio acordado', value: 'agreementPrice' },
  { ...PriceHeader, text: 'Utilidad proyectada', value: 'utility', width: 180 },
  { ...StockButtonHeader, text: 'Detalle stock', value: '@', width: 100 },
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
]

export default function (): ResourceHeaders {
  return headers
}
