import { TableCellHeader } from '@/components/dataTables/cell'
import { executive } from './executive'
import { forwarder } from './forwarder'
import { appraiser } from './appraiser'
import { inspector } from './inspector'
import { supervisor } from './supervisor'
import { validator } from './validator'
import { external } from './external'
import { advertiser } from './advertiser'
import { treasurer } from './treasurer'
import { sysop } from './sysop'

export type ResourceHeaders = Array<TableCellHeader>
export type HeadersGenerator = (...args: any) => ResourceHeaders
export type RoleResources = Record<string, HeadersGenerator>

export const resources: RoleResources = {
  ...executive,
  ...forwarder,
  ...appraiser,
  ...inspector,
  ...supervisor,
  ...validator,
  ...external,
  ...advertiser,
  ...treasurer,
  ...sysop,
}
