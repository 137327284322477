import { ResourceHeaders } from '..'
import {
  AlertsHeader,
  ContactHeader,
  DateHeader,
  FinancialHeader,
  IconHeader,
  IdHeader,
  LetterApprovalHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '@/views/datatables/resources/headers'
import { Helpers } from '@/views/datatables/resources/helpers'

import { financingStatusIcons } from '@/models/financing/interfaces'
import { Process } from '@/entities/settings'
import { Financing } from '@/entities/loans'
import { Lead } from '@/entities/crm'

export const headers: ResourceHeaders = [
  {
    ...TextHeader,
    text: 'Nº',
    value: '@',
    width: 130,
  },
  { ...DateHeader, text: 'Fecha de solicitud ', value: 'created' },
  {
    ...ContactHeader,
    text: 'Solicitante',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'applicant',
      },
      path: 'financing.applicant.applicantWithRut',
      displayPhone: true,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'applicant', process, system),
      },
      disabledMissingProperty: ['financing.applicant.phone'],
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'financing.saleOrder.deal.auto', width: 260 },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'financing.saleOrder.deal.stock' },
  { ...TextHeader, text: 'Precio venta', value: 'financing.warrantyAmountFixed' },
  { ...TextHeader, text: 'Pie ', value: 'pie' },
  { ...TextHeader, text: 'Monto solicitado', value: 'financing.startingAmountFormatted', width: 90 },
  { ...TextHeader, text: 'Monto financiado', value: 'priceAccordedFormatted' },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...TextHeader, text: 'Financiera', value: 'agreement.person.alias', width: 120 },
  { ...IconHeader, text: 'Estado negocio', value: 'financing.saleOrder.deal.status', sortable: false, width: 130 },

  {
    ...FinancialHeader,
    text: 'Estado solicitud',
    value: '@',
    options: {
      allowCreate: true,
      set: financingStatusIcons,
      showDetails: false,
    },
  },

  {
    ...ContactHeader,
    text: 'Ejecutivo financiamiento',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'financing_executive',
      },
      path: 'responsibleAgreement.responsible.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Financing, process: Process, system: string) => Helpers.contact(item, 'financing_executive', process, system),
      },
      disabledMissingProperty: ['responsibleAgreement.responsible.secondaryPhone.work'],
    },
  },
  {
    ...LetterApprovalHeader,
    text: 'Carta de aprobación',
    value: '@',
    width: 160,
  },
]

export default function (): ResourceHeaders {
  return headers
}
