import { ResourceHeaders } from '..'
import {
  AlertsHeader,
  DateHeader,
  IdHeader,
  MarketPricesHeader,
  PhotoHeader,
  RedirectDtHeader,
  StockDailyProgressHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { CellType, TableCellHeader } from '@/components/dataTables/cell'
import { extendsObj } from '@/utils/vuetify/helpers'

export const headers: ResourceHeaders = [
  extendsObj<TableCellHeader>({
    text: 'Nº',
    value: '@',
    width: 130,
    options: {
      removeOff: true,
      expandOff: true,
      show: false,
    },
  }, IdHeader),
  { ...AlertsHeader, text: 'Alerta', value: 'alerts', sortable: true },
  { ...PhotoHeader, text: 'Foto', value: '@', options: { useAuto: true } },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'SupervisorStockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado stock', value: 'stockStatus' },
  { ...TextHeader, text: 'Llave', value: 'keyIdentifier.keyId', width: 60 },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
  { ...DateHeader, text: 'Fecha último precio', value: 'created', width: 180 },
  { ...StockDailyProgressHeader, text: 'Días en stock', value: '@' },
  extendsObj<TableCellHeader>({
    text: 'Precio y descuento',
    value: 'priceDiscount',
    width: 240,
    sortable: true,
    options: {
      path: 'priceAmount',
    },
  }, MarketPricesHeader),
  {
    ...MarketPricesHeader,
    text: 'Leads',
    value: 'received',
    width: 180,
    options: {
      component: CellType.text,
      options: {
        align: 'start',
      },
    },
  },
  {
    ...MarketPricesHeader,
    text: 'Utilidad proyectada',
    value: 'utility',
    width: 130,
    options: {
      component: CellType.text,
      options: {
        align: 'center',
      },
    },
  },
  {
    ...MarketPricesHeader,
    text: 'Días',
    value: 'stockInformation',
    width: 165,
    options: {
      component: CellType.text,
      options: {
        align: 'start',
      },
    },
  },
  {
    ...RedirectDtHeader,
    text: 'Ver leads',
    value: '@',
    options: {
      path: '/datatable/supervisor/supervisor_leads',
      queryValidationString: 'leads',
      query: {
        deals: {
          stock: {
            id: {
              _eq: '$id',
            },
          },
        },
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
