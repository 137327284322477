import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BackupPaymentHeader,
  ContactHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...TextHeader, text: 'Fecha', value: 'created', width: 120 },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  { ...TextHeader, text: 'Forma de pago', value: 'type.description', width: 120 },
  { ...TextHeader, text: 'Monto', value: 'amountFixed', width: 60 },
  { ...TextHeader, text: 'Pagador', value: 'payer.shortName', width: 120 }, // id pager
  { ...TextHeader, text: 'Cuenta de abono', value: 'financialAccount.bankAccountShortName', width: 120 }, // payment count
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive_income',
      },
      path: 'deal.lead.executive.person.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive_income', process, system),
      },
      disabledMissingProperty: ['deal.lead.executive.phone'],
    },
  },
  { ...TextHeader, text: 'Responsable', value: 'responsible.person.shortName', width: 120 },
  {
    ...BackupPaymentHeader,
    text: 'Detalle',
    value: '@',
    width: 60,
    sortable: false,
    options: {
      name: 'payment_receipt',
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
