import {
  AlertStockCellDefinition,
  CellType,
  LinkedCellDefinition,
  ListComponentCellDefinition,
  RedirectDtCellDefinition,
  RemoveStockPublicationCellDefinition,
  RepublishStockCellDefinition,
  StockButtonCellDefinition,
  StockCellDefinition,
  StockCharacteristicsCellDefinition,
  StockDailyCellDefinition,
  StockDailyProgressCellDefinition,
  StockDetailStatusCellDefinition,
  StockDocumentationCellDefinition,
  StockDocumentDetailsCellDefinition,
  StockLeadReceivedCellDefinition,
} from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { VehicleStatusIcons } from '@/models/vehicle/interfaces'

export const VehicleHeader: LinkedCellDefinition = {
  align: 'center',
  width: 220,
  filterable: true,
  sortable: true,
  type: CellType.linked,
  options: {
    trigger: Helpers.linkedModel,
    allowCreate: true,
  },
}

export const VehicleStatusHeader: StockCellDefinition = {
  align: 'center',
  filterable: true,
  width: 200,
  type: CellType.stock,
  options: {
    set: VehicleStatusIcons,
  },
}

export const IndicatorHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 110,
  filterable: true,
  sortable: false,
  type: CellType.cellList,
  options: {
    component: CellType.alert,
    cols: 6,
    col: {
      pt: 2,
    },
  },
}

export const MarketPricesHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  sortable: false,
  type: CellType.cellList,
  options: {
    component: CellType.price,
  },
}

export const FileHeader: LinkedCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.linked,
  options: {
    trigger: Helpers.linkedModel,
  },
}

export const StockButtonHeader: StockButtonCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockButton,
  options: {
    trigger: Helpers.linkedModel,
  },
}

export const StockDailyHeader: StockDailyCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockDaily,
  options: {
    trigger: Helpers.linkedModel,
  },
}

export const StockLeadReceivedHeader: StockLeadReceivedCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockLeadReceived,
  options: {
    trigger: Helpers.linkedModel,
  },
}

export const StockCharacteristicsHeader: StockCharacteristicsCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockCharacteristics,
}

export const RedirectDtHeader: RedirectDtCellDefinition = {
  align: 'center',
  width: 100,
  filterable: true,
  type: CellType.redirectDt,
}

export const StockDailyProgressHeader: StockDailyProgressCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.StockDailyProgress,
}

export const RepublishStockHeader: RepublishStockCellDefinition = {
  align: 'center',
  width: 150,
  filterable: true,
  type: CellType.RepublishStockCell,
}

export const RemoveStockPublicationHeader: RemoveStockPublicationCellDefinition = {
  align: 'center',
  width: 150,
  filterable: true,
  type: CellType.removeStockPublicationCell,
}

export const AlertStockHeader: AlertStockCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.alertStock,
}

export const StockDetailsStatusHeader: StockDetailStatusCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.stockDetailStatusCell,
}

export const StockDocumentationCellHeader: StockDocumentationCellDefinition = {
  align: 'center',
  width: 200,
  filterable: false,
  type: CellType.stockDocumentationCell,
}

export const StockDocumentDetailsCellHeader: StockDocumentDetailsCellDefinition = {
  align: 'center',
  width: 200,
  filterable: false,
  type: CellType.stockDocumentDetailsCell,
}
