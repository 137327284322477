import { ResourceHeaders } from '..'
import {
  IdHeader,
  PhotoHeader,
  StockDocumentationCellHeader,
  StockDocumentDetailsCellHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  extendsObj<TableCellHeader>({
    text: 'Nº',
    value: '@',
    width: 130,
    options: {
      removeOff: true,
    },
  }, IdHeader),
  { ...PhotoHeader, text: 'Foto', value: '@' },
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  {
    ...StockDocumentationCellHeader,
    text: 'Permiso circulación ',
    value: '@',
    options: { path: 'circulation_permit' },
  },
  {
    ...StockDocumentationCellHeader,
    text: 'SOAP',
    value: '@',
    options: { path: 'soap' },
  },
  {
    ...StockDocumentationCellHeader,
    text: 'Revisión técnica',
    value: '@',
    options: { path: 'technical_review' },
  },
  {
    ...StockDocumentationCellHeader,
    text: 'CAV',
    value: '@',
    options: { path: 'cav' },
  },
  {
    ...StockDocumentationCellHeader,
    text: 'Certificado multas',
    value: '@',
    options: { path: 'penalty_certificate' },
  },
  {
    ...TextHeader, text: 'Ultima mantención', value: 'auto.lastMaintenanceValue', width: 120,
  },
  {
    ...StockDocumentDetailsCellHeader,
    text: 'Resumen',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
