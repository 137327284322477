import { ResourceHeaders } from '..'
import {
  AlertConsignmentHeader,
  ChannelHeader,
  ConsignmentExpirationHeaders,
  ConsignmentHeaders,
  ContactHeader,
  IdHeader,
  PdfConsignmentHeader,
  PdfConsignmentWithdrawalHeader,
  PdfResponsabilityHeader,
  PersonHeader,
  PriceHeader,
  StockButtonHeader,
  StockDailyHeader,
  StockLeadReceivedHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { PurchaseOrder } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
      show: true,
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  {
    ...AlertConsignmentHeader, text: 'Alerta', value: '@',
  },
  // {
  //   ...RetirementConsignmentCellHeader, text: 'Retiro', value: '@',
  // },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'negotiation.client.name',
    width: 200,
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...ConsignmentExpirationHeaders, text: 'Vencimiento', value: '@' },
  { ...ConsignmentHeaders, text: 'Estado', value: '@' },
  { ...PriceHeader, text: 'Precio acordado', value: 'agreementPrice' },
  { ...PriceHeader, text: 'Utilidad proyectada', value: 'utility', width: 180 },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'negotiation.executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: PurchaseOrder, process: Process, system: string) => { Helpers.contact(item, 'executive', process, system) },
      },
      disabledMissingProperty: ['negotiation.executive.phone'],
    },
  },
  {
    ...ContactHeader,
    text: 'Validador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'validator',
      },
      path: 'validator.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: PurchaseOrder, process: Process, system: string) => Helpers.contact(item, 'validator', process, system),
      },
      disabledMissingProperty: ['validator.phone'],
    },
  },
  { ...StockButtonHeader, text: 'Detalle Stock', value: '@', width: 140 },
  {
    ...StockLeadReceivedHeader,
    text: 'Leads recibidos',
    value: '@',
    width: 180,
  },
  {
    ...StockDailyHeader,
    text: 'Días',
    value: '@',
    width: 165,
  },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
  {
    ...PdfResponsabilityHeader,
    text: 'Carta de responsabilidad',
    value: '@',
  },

  {
    ...PdfConsignmentHeader,
    text: 'Contrato de consignación',
    value: '@',
  },
  {
    ...PdfConsignmentWithdrawalHeader,
    text: 'Retiro consignación',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
