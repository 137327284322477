import { ResourceHeaders } from '..'
import {
  AlertAppraisalHeader,
  BusinessHeader,
  ContactHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  IdHeader,
  IndicatorHeader,
  ListActionHeader,
  PersonHeader,
  PriceHeader,
  StatusAppraisalCellHeader,
  StockCharacteristicsHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { CellType } from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { Appraisal } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      removeOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertAppraisalHeader, text: 'Alerta', value: '@', sortable: false, width: 100 },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto', sortable: true },
  { ...DateHeader, text: 'Creación', value: 'created', sortable: false },
  { ...TextHeader, text: 'Kilometraje', value: 'mileageFormatted' },
  { ...StockCharacteristicsHeader, text: 'Característica', value: 'deal.auto' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'client.name',
      trigger: ({ deal: { lead: { client } } }: Appraisal) => Helpers.linkedModel(client),
      displayPhone: true,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['client.phone'],
    },
  },
  {
    ...ContactHeader,
    text: 'Tasador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'appraiser',
      },
      path: 'appraiser.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'appraiser', process, system),
      },
      disabledMissingProperty: ['appraiser.phone'],
    },
  },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...StatusAppraisalCellHeader, text: 'Estado tasación', value: '@' },

  /* {
    ...MarketPricesHeader,
    text: 'Precios de mercado',
    value: 'market.overview',
    width: 175,
    options: {
      component: CellType.price,
      trigger: Helpers.addLinkedModel('appraisal report'),
      row: {
        py: 2,
      },
    },
  }, */
  { ...PriceHeader, text: 'Diferencia para compra', value: 'agreement', width: 160 },
  { ...PriceHeader, text: 'Diferencia para consignacion', value: 'agreementConsignment', width: 160 },
  {
    ...CreateLinkedRecordHeader,
    text: 'Tareas',
    value: 'buttonActivity',
    options: {
      action: {
        color: 'primary',
        icon: 'mdi-calendar-month-outline',
        only: true,
        trigger: Helpers.linkedModel,
      },
    },
  },
  {
    ...IndicatorHeader,
    text: 'Indicadores',
    value: 'indicator',
    width: 140,
    options: {
      component: CellType.alert,
      cols: 6,
      row: {
        pt: 1,
      },
      col: {
        pt: 1,
      },
    },
  },
  {
    ...ListActionHeader,
    text: 'Agendar',
    value: 'appraisalActions',
    sortable: false,
    options: {
      cols: 12,
      component: CellType.action,
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
