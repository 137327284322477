import {
  AlertConsignmentCellDefinition,
  AlertFinancierCellDefinition,
  AlertReserveSellCellDefinition,
  CellType,
  ConsignmentExpirationCellDefinition,
  ConsignmentStatusCellDefinition,
  DailyAccountAlertCellDefinition,
  IconCellDefinition,
  LeadActivityHistoryCellDefinition,
  PdfConsignmentCellDefinition,
  PdfConsignmentWithdrawalCellDefinition,
  PdfPurchaseCellDefinition,
  PdfResponsabilityCellDefinition,
  PdfSaleCellDefinition,
  PdfWarrantyCellDefinition,
  PhotoCellDefinition,
  PurchaseStatusCellDefinition,
  RetirementConsignmentCellDefinition,
  StatusAppraisalCellDefinition,
  TotalProfitCellDefinition,
} from '@/components/dataTables/cell'

export const IconHeader: IconCellDefinition = {
  align: 'center',
  width: 40,
  filterable: false,
  type: CellType.icon,
}

export const PhotoHeader: PhotoCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  type: CellType.photo,
  options: {
    useAuto: false,
  },
}

export const AlertReserveSellCellHeader: AlertReserveSellCellDefinition = {
  type: CellType.alertReserveSell,
  align: 'center',
  width: 60,
  sortable: false,
  filterable: false,
}

export const PdfWarrantyHeader: PdfWarrantyCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfWarranty,
  options: {
    tooltip: 'Abrir garantia de postventa',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-shield-star-outline',
      only: true,
    },
  },
}

export const PdfSaleHeader: PdfSaleCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfSale,
  options: {
    tooltip: 'Abrir nota de venta',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-file-document-arrow-right-outline',
      only: true,
    },
  },
}

export const PdfResponsabilityHeader: PdfResponsabilityCellDefinition = {
  align: 'center',
  width: 130,
  filterable: false,
  sortable: false,
  type: CellType.pdfResponsability,
  options: {
    tooltip: 'Abrir carta de responsabilidad',
    disabled: true,
    action: {
      color: 'purple',
      icon: 'mdi-clipboard-account-outline',
      only: true,
    },
  },
}

export const PdfPurchaseHeader: PdfPurchaseCellDefinition = {
  align: 'center',
  width: 130,
  filterable: false,
  sortable: false,
  type: CellType.pdfPurchase,
  options: {
    tooltip: 'Abrir nota de compra',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-file-document-check-outline',
      only: true,
    },
  },
}

export const PdfConsignmentHeader: PdfConsignmentCellDefinition = {
  align: 'center',
  width: 130,
  filterable: false,
  sortable: false,
  type: CellType.pdfConsignment,
  options: {
    tooltip: 'Abrir contrato de consignación',
    disabled: true,
    action: {
      color: 'blue',
      icon: '$car_key',
      only: true,
    },
  },
}

export const PdfConsignmentWithdrawalHeader: PdfConsignmentWithdrawalCellDefinition = {
  align: 'center',
  width: 130,
  filterable: false,
  sortable: false,
  type: CellType.pdfConsignmentWithdrawal,
  options: {
    tooltip: 'Abrir contrato de retiro consignación',
    disabled: true,
    action: {
      color: 'purple',
      icon: '$key_withdrawal',
      only: true,
    },
  },
}

export const ConsignmentHeaders: ConsignmentStatusCellDefinition = {
  align: 'center',
  width: 180,
  filterable: false,
  sortable: false,
  type: CellType.statusConsignment,
}

export const ConsignmentExpirationHeaders: ConsignmentExpirationCellDefinition = {
  align: 'center',
  width: 180,
  filterable: false,
  sortable: false,
  type: CellType.expirationConsignment,
}

export const DailyAccountAlertHeader: DailyAccountAlertCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.dailyAccountAlert,
}

export const AlertConsignmentHeader: AlertConsignmentCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.alertConsignment,
}

export const AlertPurchaseHeader: PurchaseStatusCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.purchaseStatusCell,
}

export const LeadActivityHistoryHeader: LeadActivityHistoryCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.leadActivityHistory,
}

export const TotalProfitHeader: TotalProfitCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.totalProfit,
}

export const StatusAppraisalCellHeader: StatusAppraisalCellDefinition = {
  type: CellType.statusAppraisalCell,
  align: 'center',
  width: 250,
  sortable: false,
  filterable: false,
}

export const RetirementConsignmentCellHeader: RetirementConsignmentCellDefinition = {
  type: CellType.retirementConsignmentCell,
  align: 'center',
  width: 180,
  sortable: false,
  filterable: false,
}

export const AlertFinancierCellHeader: AlertFinancierCellDefinition = {
  type: CellType.alertFinancier,
  align: 'center',
  width: 130,
  sortable: false,
  filterable: false,
}
